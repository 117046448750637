import common from "./common";
import placeholder from "./placeholder";
import message from "./message";
import ai from "./ai";
import login from "./login";
import register from "./register";
import forgotPassword from "./forgotPassword";
import post from "./post";
import channel from "./channel";
import subscription from "./subscription";
import profile from "./profile";
import team from "./team";
import errorPage from "./errorPage";
import uploadcarewidget from "./uploadcarewidget";
import media from "./media";
import urlshortener from "./urlshortener";
import preConnect from "./preConnect";
import channelCat from "./channelCat";
import postTag from "./postTag";
import automation from "./automation";
import postCollection from "./postCollection";
import postIdea from "./postIdea";
import scheduledPostDiscussion from "./scheduledPostDiscussion";
import contact from "./contact";
import shortUrl from "../en/shortUrl.json";

export const ja = {
  common,
  placeholder,
  message,
  ai,
  login,
  register,
  forgotPassword,
  post,
  channel,
  subscription,
  profile,
  team,
  errorPage,
  uploadcarewidget,
  media,
  urlshortener,
  preConnect,
  channelCat,
  postTag,
  automation,
  postCollection,
  postIdea,
  scheduledPostDiscussion,
  contact,
  shortUrl
};
